const accountLang = {
  en: {
    profile: "Profile",
    myProfile: "My profile",
    settingProfile: "Account settings",
    profileOverview: "Overview",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    details: "Details",
    editProfile: "Edit profile",
    user_no_authorized: "The credentials are wrong",
    fullName: "Full name",
    phone: "Phone number",
    user: "User",
    solvency: "Solvency",
    profileDetails: "Profile details",
    nit: "NIT",
    fullNameRequired: "Full name is a required field",
    phoneRequired: "Phone nombre is a required field",
    nitRequired: "NIT is a required field",
    user_updated_successful: "User has updated correctly",
    updatePassword: "Update password",
    labelPassword: "The password must contain at least 8 characters",
    auth: "Authentication",
    resetPassword: "Change password",
    currentPassword: "Current password",
    newPassword: "New password",
    confirmNewPassword: "Confirm new password",
    newPasswordRequired: "New password is a required field",
    confirmNewPasswordRequired: "Confirm new password is a required field",
    passwordMustMatch: "Password does not match",
    user_password_error: "Please, complete the required fields",
    user_current_password_error: "The current password is wrong",
    user_password_updated_successful: "Your password has updated correctly",
    attentionReport: "Attention Report",
    selectDateRange: "Select date range",
    month: "Month",
    generate: "Generate",
  },
  es: {
    profile: "Perfil",
    myProfile: "Mi perfil",
    settingProfile: "Ajustes de perfil",
    profileOverview: "Descripción general",
    account: "Cuenta",
    accountOverview: "Descripción general",
    settings: "Ajustes",
    details: "Detalles",
    editProfile: "Editar mi perfil",
    user_no_authorized: "Las credenciales son incorrectas",
    fullName: "Nombre completo",
    phone: "Número telefónico",
    user: "Usuario",
    solvency: "Solvencia",
    profileDetails: "Detalles de perfil",
    nit: "NIT",
    fullNameRequired: "Nombre completo es un campo requerido",
    phoneRequired: "Número telefónico es un campo requerido",
    nitRequired: "NIT es un campo requerido",
    user_updated_successful: "Usuario se ha actualizado correctamente",
    updatePassword: "Actualizar contraseña",
    labelPassword: "La contraseña debe contener al menos 8 caracteres",
    auth: "Autenticación",
    resetPassword: "Cambiar contraseña",
    currentPassword: "Contraseña actual",
    newPassword: "Nueva contraseña",
    confirmNewPassword: "Confirmar nueva contraseña",
    newPasswordRequired: "Nueva contraseña es un campo requerido",
    confirmNewPasswordRequired:
      "Confirmar nueva contraseña es un campo requerido",
    passwordMustMatch: "La contraseña no coincide",
    user_password_error: "Por favor, completar los campos requeridos",
    user_current_password_error: "La contraseña actual es incorrecta",
    user_password_updated_successful:
      "Su contraseña ha sido actualizada correctamente",
    attentionReport: "Reporte de atenciones",
    selectDateRange: "Seleccionar período",
    month: "Mes",
    generate: "Generar",
  },
};
export default accountLang;
